<template>
  <div class="p-5 flex flex-column">
    <div class="tab-container">
      <content-card-full>
        <template #title>
          <card-title>
            <template #title><h2>Invest Now</h2></template>
          </card-title>
        </template>
        <template #content-full>

          <ProgressStepper :steps="stepOptions" :defaultPath="'/investments/add/provider'" />

          <router-view></router-view>

        </template>
      </content-card-full>
    </div>
  </div>
</template>

<script>
import CardTitle from "@/components/common/CardTitle";
import ContentCardFull from "@/components/common/ContentCardFull";

import {computed} from 'vue';
import ProgressStepper from "@/components/common/ProgressStepper";
import {useStore} from "vuex";

export default {
  name: "AddInvestment",
  components: {
    ProgressStepper,
    CardTitle,
    ContentCardFull,
  },
  setup() {
    const store = useStore()
    const stepOptions = computed(() => store.getters.getSteps('investnow'))

    return {
      stepOptions
    }
  }
}
</script>

<style scoped>

</style>